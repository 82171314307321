<template>
  <div class="home">
    <div class="content-wrapper">
      <div class="equalizer">
        <div class="bar blue"></div>
        <div class="bar blue"></div>
        <div class="bar blue"></div>
        <div class="bar blue"></div>
        <div class="bar purple"></div>
        <div class="bar purple"></div>
        <div class="bar purple"></div>
        <div class="bar purple"></div>
      </div>
      
      <div class="animated-title-container">
        <h1 class="animated-title">
          <span class="blue-neon">BEAT</span>
          <span class="purple-neon">PRESSURE</span>
        </h1>
        <h3 class="tagline"><span class="blue-neon">next-generation</span> <span class="purple-neon">rhythm game</span><br><br><span class="blue-neon">Based on Public Pressure's </span> <span class="purple-neon">music collection</span></h3>
      </div>

      <div class="button-container">
        <button class="play-button" @click="$router.push('/play')">
          <div class="vinyl-record"></div>
          <span>Play in browser</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noventa', sans-serif;
}

.home {
  height: calc(100vh - 60px);
  width: 100vw;
  overflow: hidden;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/backgrounds/rhythm1.webp');
  background-size: cover;
}

.home:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 1.0));
}

.content-wrapper {
  text-align: center;
  z-index: 10;
  padding: 1rem;
  max-width: 100%;
}

.equalizer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
  margin-bottom: 3rem;
  position: relative;
}

.bar {
  width: 8px;
  margin: 0 4px;
  animation: equalize 1s infinite alternate;
  position: relative;
}

.bar.blue {
  background-color: #00cccc;
  box-shadow: 
    0 0 5px #00cccc,
    0 0 10px #00cccc,
    0 0 15px #00cccc,
    0 0 20px #00cccc;
}

.bar.purple {
  background-color: #ee00ee;
  box-shadow: 
    0 0 5px #ee00ee,
    0 0 10px #ee00ee,
    0 0 15px #ee00ee,
    0 0 20px #ee00ee;
}

.bar:nth-child(1) { animation-duration: 0.8s; }
.bar:nth-child(2) { animation-duration: 1s; }
.bar:nth-child(3) { animation-duration: 1.2s; }
.bar:nth-child(4) { animation-duration: 0.9s; }
.bar:nth-child(5) { animation-duration: 1.1s; }
.bar:nth-child(6) { animation-duration: 0.7s; }
.bar:nth-child(7) { animation-duration: 1.3s; }
.bar:nth-child(8) { animation-duration: 1s; }

@keyframes equalize {
  0% { height: 5px; }
  100% { height: 50px; }
}

.animated-title-container {
  margin-bottom: 3rem;
  position: relative;
}

.animated-title {
  font-size: 4rem;
  font-weight: 700;
  padding: 0.5em;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.blue-neon, .purple-neon {
  display: inline-block;
}

.blue-neon {
  color: #ffffff;
  text-shadow: 0 0 5px #00cccc, 0 0 10px #00cccc, 0 0 20px #00cccc, 0 0 30px #00cccc, 0 0 40px #00cccc;
  animation: blue-neon-pulse 1.5s infinite alternate;
  margin-right: 0.3em;
}

.purple-neon {
  color: #ffffff;
  text-shadow: 0 0 5px #ee00ee, 0 0 10px #ee00ee, 0 0 20px #ee00ee, 0 0 30px #ee00ee, 0 0 40px #ee00ee;
  animation: purple-neon-pulse 1.5s infinite alternate;
  margin-left: 0.3em;
}

@keyframes blue-neon-pulse {
  from { text-shadow: 0 0 5px #00cccc, 0 0 10px #00cccc, 0 0 20px #00cccc, 0 0 30px #00cccc, 0 0 40px #00cccc; }
  to { text-shadow: 0 0 2px #00cccc, 0 0 5px #00cccc, 0 0 10px #00cccc, 0 0 20px #00cccc, 0 0 30px #00cccc; }
}

@keyframes purple-neon-pulse {
  from { text-shadow: 0 0 5px #ee00ee, 0 0 10px #ee00ee, 0 0 20px #ee00ee, 0 0 30px #ee00ee, 0 0 40px #ee00ee; }
  to { text-shadow: 0 0 2px #ee00ee, 0 0 5px #ee00ee, 0 0 10px #ee00ee, 0 0 20px #ee00ee, 0 0 30px #ee00ee; }
}

.animated-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-top: 4px solid #00cccc;
  border-left: 4px solid #00cccc;
}

.animated-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  border-bottom: 4px solid #ee00ee;
  border-right: 4px solid #ee00ee;
}

.tagline {
  font-size: 1.2rem;
  color: #f0f0f0;
  text-shadow: 0 0 5px #00cccc, 0 0 5px #ee00ee;
  margin-top: 1.5rem;
}

.button-container {
  margin-top: 3rem;
}

.play-button {
  position: relative;
  padding: 1rem 3rem 1rem 5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-left: 2px solid #00cccc;
  border-right: 2px solid #ee00ee;
  border-radius: 50px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5), 0 0 10px rgba(255, 0, 255, 0.5);
}

.play-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #00cccc, transparent, #ee00ee);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.play-button:hover::before {
  opacity: 0.2;
}

.play-button:hover {
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.8), 0 0 20px rgba(255, 0, 255, 0.8);
}

.vinyl-record {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: conic-gradient(#333, #000, #333, #000, #333);
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.vinyl-record::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #00cccc;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: translateY(-50%) rotate(0deg); }
  100% { transform: translateY(-50%) rotate(360deg); }
}

/* Responsive styles */
@media (max-width: 768px) {
  .home {
    height: 100vh;
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .equalizer {
    margin-bottom: 1rem;
  }

  .animated-title {
    font-size: 2.5rem;
    padding: 0.3em;
    line-height: 1.4;
  }

  .animated-title::before,
  .animated-title::after {
    width: 50px;
    height: 50px;
  }

  .tagline {
    font-size: 1rem;
    margin-top: 1rem;
  }

  .button-container {
    margin-top: 1rem;
  }

  .play-button {
    padding: 0.8rem 2rem 0.8rem 4rem;
    font-size: 1.2rem;
  }

  .vinyl-record {
    width: 30px;
    height: 30px;
  }

  .vinyl-record::after {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 480px) {
  .animated-title {
    font-size: 2rem;
    line-height: 1.6;
  }

  .tagline {
    font-size: 0.9rem;
  }

  .play-button {
    padding: 0.7rem 1.5rem 0.7rem 3.5rem;
    font-size: 1rem;
  }

  .vinyl-record {
    width: 25px;
    height: 25px;
  }

  .vinyl-record::after {
    width: 6px;
    height: 6px;
  }
}
</style>