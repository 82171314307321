<template>
  <nav class="navbar">
    <div class="nav-items-container">
      <div class="nav-item">
        <!-- Site Logo -->
        <router-link to="/" class="navbar-logo">
          <!-- <img src="@/assets/logo.png" alt="Beat Pressure Logo" /> -->
          <!-- <span class="navbar-site-title"><b>Beat Pressure</b></span> -->
        </router-link>
      </div>

      <div class="nav-item nav-item-navbar-menu">
        <!-- Menu Items -->
        <ul class="navbar-menu">
          <li v-for="item in menuItems" :key="item.name">
            <router-link :to="item.path" class="menu-item" :class="{ active: $route.path === item.path }">
              {{ item.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'Play', path: '/play' },
    // { name: 'Get songs', path: '/songs' },
    // { name: 'Level editor', path: '/editor' }
  ]
</script>

<style>

.navbar {
  font-family: 'Noventa', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1a1a1aaf;
  background: linear-gradient(90deg, rgb(37 37 37) 0%, rgb(25 25 25) 19%, rgb(13 13 13) 30%, rgb(13 13 13) 50%, rgb(12 12 12) 69%, rgb(6 6 6) 84%, rgb(5 5 5) 93%, rgb(7 7 7) 100%);;
  padding: 0px;
  position: relative;
  z-index: 100;
}

.nav-items-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.navbar-logo {
  display: inline-block;
  height: 50px;
}

.navbar-site-title {
  display: inline-block;
  line-height: 50px;
  vertical-align: top;
  margin-left: 20px;
  font-size: 40px;
  color: #f8b9ff;
  white-space: nowrap;
  text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff, 0 0 20px #ff00ff;
}

.navbar-logo img {
  height: 50px;
  vertical-align: top;
}

.navbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 5px;
}

.navbar-menu li {
  display: inline-block;
  white-space: nowrap;
  padding: 15px 0 0 0;
}

.nav-item-navbar-menu {
  display: flex;
  float: right;
}

@media (max-width: 732px) {
  .navbar-menu {
    margin-top: 35px;
    padding-bottom: 20px;
  }
  .navbar {
    padding: 5px;
  }
  .nav-item {
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.menu-item {
  color: #ffffff;
  font-weight: bold;
  margin-left: 40px;
  text-decoration: none;
  text-shadow: 0 0 5px #000000, 0 0 10px #0f0f0f;
  transition: text-shadow 0.3s ease;
}

.menu-item:hover {
  text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff;
}

.menu-item.active {
  color: #ffaaff;
  text-shadow: 0 0 5px #ff00ff, 0 0 10px #ff00ff, 0 0 15px #ff00ff;
}
</style>